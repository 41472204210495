export enum BusinessErrorCodes {
  IS_CFA = "IS_CFA",
  ALREADY_EXISTS = "ALREADY_EXISTS",
  EMAIL_ALREADY_EXISTS = "EMAIL_ALREADY_EXISTS",
  CLOSED = "CLOSED",
  NON_DIFFUSIBLE = "NON_DIFFUSIBLE",
  UNKNOWN = "UNKNOWN",
  UNSUPPORTED = "UNSUPPORTED",
  NOT_QUALIOPI = "NOT_QUALIOPI",
  TOO_MANY_APPLICATIONS_PER_DAY = "TOO_MANY_APPLICATIONS_PER_DAY",
  TOO_MANY_APPLICATIONS_PER_OFFER = "TOO_MANY_APPLICATIONS_PER_OFFER",
  TOO_MANY_APPLICATIONS_PER_SIRET = "Vous avez atteint le quota maximum de candidatures pour ce SIRET",
  BURNER = "L'email est invalide.",
  NOTFOUND = "Aucune offre correspondante trouvée.",
  EXPIRED = "EXPIRED",
  ATTACHMENT = "Pièce jointe invalide.",
  INTERNAL_EMAIL = "Aucun email pour l'offre trouvée.",
  ROMEO_NOT_FOUND = "Une erreur est survenue lors de la récupération du code ROME à partir du titre de l'offre",
  GEOLOCATION_NOT_FOUND = "Une erreur est survenue lors de la récupération de la géolocalisation à partir de l'adresse de l'entreprise",
}
